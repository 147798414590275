import React from 'react';
import Layout from '../../components/Layout';

import HeroTitle from '../../components/HeroTitle';

export default () => (
  <Layout>
    <main>
      <HeroTitle title="Success! 👌🏻" />
      <div className="strip">
        <div className="row">
          <p>
            Your message has been successfully sent. We'll aim to get back to
            you within 24 hours.
          </p>
          <p>
            If your message is urgent, please call us on the telephone numbers
            listed below{' '}
            <span role="img" aria-label="Look below">
              👇🏻
            </span>
          </p>
        </div>
      </div>
    </main>
  </Layout>
);
